<template>
  <div class="flex w-max">
    <router-link :to="{ name: 'Top' }" class="sb-link mr-4 w-max"
      >Топ 15</router-link
    >
    <a :href="rulesLink" target="_blank" class="sb-link">Правила</a>
  </div>
</template>

<script>
import { DOCS } from '@/utils/consts.js';

export default {
  name: 'LinksMain',
  components: {},
  data() {
    return {
      rulesLink: DOCS.RULES,
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
