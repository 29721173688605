<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8817 7.23935V9.10701H15.7495V15.6105H13.8819V17.478H9.67932V15.6104H13.8817V14.2097C13.3147 14.5432 12.681 14.71 12.0139 14.71C9.946 14.71 8.27832 13.0424 8.27832 10.9747C8.27832 8.9069 9.946 7.23935 12.0139 7.23935H12.214H13.8817ZM12.0139 12.809C12.5476 12.809 13.0145 12.6089 13.3481 12.2754C13.6816 11.9419 13.8817 11.4749 13.8484 10.9413C13.8484 10.4077 13.6483 9.94079 13.3147 9.60728C13.0145 9.30712 12.5809 9.10701 12.114 9.07366H11.9806C11.4803 9.07366 11.0133 9.27377 10.6798 9.60728C10.3462 9.97414 10.1461 10.4411 10.1461 10.9413C10.1461 11.9752 10.98 12.809 12.0139 12.809Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "IconGeeckoLogoRounded",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {}
};
</script>
