<style lang='postcss'>
.sb-btn.success {
  background: linear-gradient(0deg, #4a9563, #4a9563), #00a337;
}
</style>

<template>
  <div class="flex items-center">
    <input v-model="linkToCopy" class="sb-input" type="text" />
    <button class="sb-btn sb-btn--icon ml-4" :class="{success: copied, 'animate-ping': copied}" @click="copyLink">
      <component :is="copied ? 'icon-check' : 'icon-copy'" />
    </button>
  </div>
</template>

<script>
import IconCopy from "@/components/Icons/IconCopy.vue";
import IconCheck from "@/components/Icons/IconCheck.vue";

export default {
  name: "CopyLink",
  components: { IconCopy, IconCheck },
  data() {
    return {
      linkToCopy: "",
      copied: false,
    };
  },
  props: {
    link: {
      type: String,
      required: true,
    }
  },
  created() {
    this.linkToCopy = this.link;
  },
  mounted() {},
  methods: {
    copyLink() {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          this.copied = true;
          this.$store.dispatch("game/setMarquee", {
            text: "Ссылка скопирована",
            type: "success",
          });
          setTimeout(() => (this.copied = false), 1000);
        })
        .catch(err => {
          console.log("Something went wrong", err);
        });
    }
  },
  computed: {}
};
</script>
