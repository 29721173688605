<style lang='postcss'>
.digital-time {
  @apply px-3 font-sb_sans_text w-max block;
  height: 32px;
  border: 1px solid #e5dfc8;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  border-radius: 4px;
  color: #e5dfc8;
}
</style>

<template>
  <div class="digital-time">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: "DigitalTimeValue",
  components: {},
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
