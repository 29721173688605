<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 12.5H9.16667C8.24619 12.5 7.5 11.7538 7.5 10.8333V3.33333C7.5 2.41286 8.24619 1.66667 9.16667 1.66667H16.6667C17.5871 1.66667 18.3333 2.41286 18.3333 3.33333V10.8333C18.3333 11.7538 17.5871 12.5 16.6667 12.5Z"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16602 7.5H3.33268C2.89065 7.5 2.46673 7.6756 2.15417 7.98816C1.84161 8.30072 1.66602 8.72464 1.66602 9.16667V16.6667C1.66602 17.1087 1.84161 17.5326 2.15417 17.8452C2.46673 18.1577 2.89065 18.3333 3.33268 18.3333H10.8327C11.2747 18.3333 11.6986 18.1577 12.0112 17.8452C12.3238 17.5326 12.4993 17.1087 12.4993 16.6667V15.8333"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCopy",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {}
};
</script>
