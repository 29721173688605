<style lang='postcss'>
.level-block {
  @apply flex rounded-lg mb-6 md:mb-8 text-left flex-col md:flex-row;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  border: 1px solid #363636;
  &.complete {
    background: rgba(255, 255, 255, 0.05);
    & .level-block__level {
      color: #66ae3a;
    }
  }
  &__left {
    @apply flex flex-col mb-8 md:mb-0 pt-4 md:pt-6 px-6 md:px-8 pb-6;
    width: 100%;
  }
  &__right {
    width: 100%;
  }
  &__level {
    @apply font-sb_sans_caps relative flex items-center;
    font-size: 20px;
    line-height: 120%;
    color: #2cdb67;
    & svg {
      margin-left: 6px;
    }
  }
  &__title {
    @apply font-sb_sans_caps mt-3 md:max-w-320px;
    font-size: 24px;
    line-height: 26px;
  }
  &__title-secondary {
    @apply font-chava uppercase;
    font-size: 20px;
    line-height: 26px;
  }
}
@screen md {
  .level-block {
    &__left {
      width: 56%;
    }
    &__right {
      width: 44%;
    }
    &__title {
      font-size: 32px;
      line-height: 35px;
    }
  }
}
</style>

<template>
  <div class="level-block" :class="{ complete: complete }">
    <div class="level-block__left">
      <div class="level-block__level">
        <slot name="title">
          {{ levelTitle }}
          <icon-done v-if="complete" />
        </slot>
      </div>
      <div v-if="title" class="level-block__title">{{ title }}</div>
      <slot name="left"></slot>
    </div>
    <div class="level-block__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import IconDone from "@/components/Icons/IconDone.vue";

export default {
  name: "LevelBlockBase",
  components: { IconDone },
  data() {
    return {};
  },
  props: {
    complete: { type: Boolean, default: false },
    level: {
      type: Number,
      validator: (val) => {
        return [1, 2, 3].includes(val);
      },
    },
    title: {
      type: String,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    levelTitle() {
      return `${this.level} уровень ${this.complete ? "пройден" : ""}`;
    },
  },
};
</script>
