<style lang="postcss">
</style>

<template>
  <button class="sb-btn sb-btn--with-icon ym-geecko-id" @click="gameAuthShow = true">
    <icon-geecko-logo-rounded />Представиться и пройти
    <game-auth
      v-if="gameAuthShow"
      @done="nextStage()"
      @closed="gameAuthShow = false"
    />
  </button>
</template>

<script>
import IconGeeckoLogoRounded from "@/components/Icons/IconGeeckoLogoRounded.vue";
import GameAuth from "./GameAuth.vue";
export default {
  name: "LoginButton",
  components: { IconGeeckoLogoRounded, GameAuth },
  props: {},
  data() {
    return {
      gameAuthShow: false,
    };
  },
  computed: {},
  methods: {
    nextStage() {
      this.gameAuthShow = false;
      this.$router.push({ name: "Game" });
      setTimeout(() => {
        document.location.reload();
      }, 200);
    },
  },
};
</script>
